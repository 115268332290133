@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 255, 255, 255;
  --background-rgb: 0, 0, 0;
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: rgb(var(--background-rgb));
  overflow-x: hidden;
}

/* Ensure content has proper z-index to appear above background */
main {
  position: relative;
  z-index: 10;
}

/* Add some subtle transparency to cards to let background show through */
.border {
  background-color: rgba(30, 30, 30, 0.5);
  backdrop-filter: blur(2px);
}
